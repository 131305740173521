// Start with assigning color names to specific hex values.
$white:                     #ffffff  !default;
$black:                     #000000  !default;
$red:                       #001c2c  !default;
$orange:                    #001c2c  !default;
$gold:                      #001c2c  !default;
$green:                     #21B573  !default;
$blue:                      #001c2c  !default; 
$cyan:                      #04d182  !default;
$purple:                    #001c2c  !default; 
$magenta:                   #eb2f96  !default;
$volcano:                   #ff6b72  !default;
$lime:                      #a0d911  !default;
$geekblue:                  #001c2c  !default;

// Create grayscale
$gray-dark:                 #001c2c  !default;
$gray:                      #455560  !default;
$gray-light:                #72849a  !default; 
$gray-lighter:              #ededed  !default;
$gray-lightest:             #f7f7f8  !default;

// Gruu colors
$gruu-primary:               #001c2c  !default;
$brand-primary:              #001c2c  !default;
$brand-secondary:            #001c2c  !default;
$brand-accent:               #b7b7b7  !default;

$sidebar-text-active:		$gruu-primary !default;
$sidebar-bg-active:			$brand-accent !default;